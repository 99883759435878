import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { LoadingStatusEnum } from 'common/types';
import { passwordValidationSchema } from 'common/utils';
import { PasswordComplexity } from 'pages/general-pages/auth-pages/assets';
import { IDeleteAccountRequest } from 'services/auth/auth.serviceTypes';
import { loadingSelector } from 'store/reducers/appSlice';
import {
  checkCurrentPassword,
  deleteCurrentAccount,
} from 'store/reducers/authSlice/thunks';
import { Button, Input } from 'ui-kit';

import style from './DeleteAccountForm.module.scss';

const CONFIRM_TRIGGER_FIELD = 'confirm_password';

interface IDeleteAccountForm {
  setOpenModal: (value: boolean) => void;
  current_password: string;
  confirm_password: string;
}

const schema = yup
  .object({
    current_password: passwordValidationSchema,
    confirm_password: yup
      .string()
      .oneOf([yup.ref('current_password')], 'Passwords must match'),
  })
  .required();

export const DeleteAccountForm = (): JSX.Element => {
  const {
    register,
    watch,
    trigger,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<Omit<IDeleteAccountForm, 'setOpenModal'>>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const dispatch = useAppDispatch();
  const loading = useAppSelector(loadingSelector);
  const isLoading = loading === LoadingStatusEnum.Loading;
  const watchPassword = watch('current_password');

  useEffect(() => {
    if (watch(CONFIRM_TRIGGER_FIELD)) trigger(CONFIRM_TRIGGER_FIELD);
  }, [watchPassword]);

  const onSubmit = async (data: IDeleteAccountRequest): Promise<void> => {
    const actionResult = await dispatch(
      checkCurrentPassword({ current_password: data.current_password }),
    );

    if (checkCurrentPassword.fulfilled.match(actionResult)) {
      dispatch(deleteCurrentAccount());
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
      <Input
        {...register('current_password')}
        classNameWrapper={style.input_wrapper}
        placeholder="Password"
        type="password"
        error={errors.current_password?.message}
        disabled={isLoading}
      />

      <Input
        {...register('confirm_password')}
        placeholder="Confirm password"
        classNameWrapper={style.input_wrapper}
        type="password"
        error={errors.confirm_password?.message}
        disabled={isLoading}
      />
      <PasswordComplexity password={watchPassword} />
      <Button
        label="Continue"
        type="submit"
        disabled={!isValid || isLoading}
        className={style.button_submit}
      />
    </form>
  );
};
