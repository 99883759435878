import React from 'react';

import { createBrowserRouter, RouteObject } from 'react-router-dom';

import {
  ABOUT,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  VERIFY_CURRENT_EMAIL,
  VERIFY_NEW_EMAIL,
  CHECK_EMAIL,
  CONFIRM_EMAIL,
  CONTACT,
  FAQ,
  FORGOT_PASSWORD,
  HOME,
  LOGIN,
  NEWS,
  PRIVACY_POLICY,
  PRODUCTS_LIST,
  REGISTER,
  RESET_PASSWORD,
  SELL,
  TERMS_AND_CONDITIONS,
  TUTORIALS,
  sellerRoutes,
  supplierRoutes,
  convertCombinedPrivateRoutes,
  PRODUCT_DETAILS,
  DELETE_ACCOUNT,
  ADD_NEW_EMAIL,
  PRODUCT_REVIEWS,
  PRODUCT_ID,
} from '.';

import { UserRoleType } from 'common/types';
import { Layout } from 'layouts';
import {
  AboutUsPage,
  ChangeEmailPage,
  ChangePasswordPage,
  VerifyCurrentEmailPage,
  ConfirmEmailPage,
  ContactSupportPage,
  FAQPage,
  ForgotPasswordPage,
  LastNewsPage,
  PrivacyPolicyPage,
  ProductListPage,
  ResetPasswordPage,
  SellAbraPage,
  TermsAndConditionsPage,
  TutorialPage,
  CheckEmailPage,
  LoginPage,
  RegisterPage,
  MainPage,
  ErrorPage,
  ProductPage,
  DeleteAccountPage,
  ProductAllReviews,
  AddNewEmailPage,
  VerifyNewEmailPage,
} from 'pages/general-pages';

type Routes = ReturnType<typeof createBrowserRouter>;

export function createRoutes(userRole: UserRoleType): Routes {
  let child: RouteObject[] = [];

  if (userRole === 'supplier') child = supplierRoutes;
  if (userRole === 'seller') child = sellerRoutes;
  if (userRole === null) child = convertCombinedPrivateRoutes();

  return createBrowserRouter([
    {
      path: HOME,
      errorElement: (
        <main>
          <ErrorPage />
        </main>
      ),
      children: [
        ...child,
        {
          path: REGISTER,
          element: <RegisterPage />,
        },
        {
          path: LOGIN,
          element: <LoginPage />,
        },
        {
          path: FORGOT_PASSWORD,
          element: <ForgotPasswordPage />,
        },
        {
          path: RESET_PASSWORD,
          element: <ResetPasswordPage />,
        },
        {
          path: CHANGE_PASSWORD,
          element: <ChangePasswordPage />,
        },
        {
          path: CHANGE_EMAIL,
          element: <ChangeEmailPage />,
        },
        {
          path: VERIFY_CURRENT_EMAIL,
          element: <VerifyCurrentEmailPage />,
        },
        {
          path: ADD_NEW_EMAIL,
          element: <AddNewEmailPage />,
        },
        {
          path: VERIFY_NEW_EMAIL,
          element: <VerifyNewEmailPage />,
        },
        {
          path: CONFIRM_EMAIL,
          element: <ConfirmEmailPage />,
        },
        {
          path: CHECK_EMAIL,
          element: <CheckEmailPage />,
        },
        {
          path: DELETE_ACCOUNT,
          element: <DeleteAccountPage />,
        },
        {
          path: PRODUCTS_LIST,
          element:
            userRole === 'seller' || userRole === null ? <Layout /> : <ErrorPage />,
          children: [
            {
              path: ':id?',
              element: <ProductListPage />,
            },
          ],
        },
        {
          path: TERMS_AND_CONDITIONS,
          element: <TermsAndConditionsPage />,
        },
        {
          path: PRIVACY_POLICY,
          element: <PrivacyPolicyPage />,
        },
        {
          path: HOME,
          element: <Layout />,
          children: [
            {
              index: true,
              element: <MainPage />,
            },
            {
              path: PRODUCT_DETAILS,
              children: [
                {
                  path: PRODUCT_ID,
                  element: <ProductPage />,
                },
                {
                  path: PRODUCT_ID + PRODUCT_REVIEWS,
                  element: <ProductAllReviews />,
                },
              ],
            },
            {
              path: PRODUCT_REVIEWS,
              element: <ProductAllReviews />,
            },
            {
              path: NEWS,
              element: <LastNewsPage />,
            },
            {
              path: CONTACT,
              element: <ContactSupportPage />,
            },
            {
              path: SELL,
              element: <SellAbraPage />,
            },
            {
              path: TUTORIALS,
              element: <TutorialPage />,
            },
            {
              path: ABOUT,
              element: <AboutUsPage />,
            },
            {
              path: FAQ,
              element: <FAQPage />,
            },
          ],
        },
        {
          path: HOME,
          element: (
            <Layout
              headerVariant={
                userRole === 'seller' || userRole === null ? 'default' : 'none'
              }
            />
          ),
          children: [
            {
              path: PRODUCTS_LIST,
              children: [
                {
                  path: ':id?',
                  element:
                    userRole === 'seller' || userRole === null ? (
                      <ProductListPage />
                    ) : (
                      <ErrorPage />
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
  ]);
}
