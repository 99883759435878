import React from 'react';

import { useUrlSearch } from 'common/hooks';
import { HeaderActions } from 'layouts/Header/components';
import { MainLogo, Search } from 'ui-kit';

import style from './Top.module.scss';

export const Top = (): JSX.Element => {
  const { value, handleChangeValue, handleEnterKeyDown, handleRemoveValue } =
    useUrlSearch();

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <MainLogo />
        <Search
          placeholder="Search"
          value={value}
          onChange={handleChangeValue}
          onKeyDown={handleEnterKeyDown}
          handleRemoveValue={handleRemoveValue}
        />
        <HeaderActions />
      </div>
    </div>
  );
};
