import React from 'react';

import { Title } from 'ui-kit';

import style from './Tutorial.module.scss';

export const TutorialPage = (): JSX.Element => {
  return (
    <div className={style.container}>
      <Title>Coming soon...</Title>
      {/* <p className={style.title}>Tutorial for Buyers</p> */}
    </div>
  );
};
