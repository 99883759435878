import React from 'react';

import { Title } from 'ui-kit';

import style from './PriceManagementPage.module.scss';

export const PriceManagementPage = (): JSX.Element => {
  return (
    <div className={style.price_management_page_container}>
      <Title>Coming soon...</Title>
    </div>
  );
};
