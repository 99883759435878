import { TableHeader } from './TableHeader/TableHeader';
import { TableList } from './TableList/TableList';

import { useAppSelector } from 'common/hooks';
import { tableSortData } from 'pages/supplier-pages/pages/SupplierProducts/common/utils/tableData';
import { sortedProductSelector } from 'store/reducers/supplier/product';

import style from './ProductsTable.module.scss';

export const ProductsTable = (): JSX.Element => {
  const products = useAppSelector(sortedProductSelector);

  return (
    <table className={style.table}>
      <TableHeader tableSortData={tableSortData} />
      <TableList products={products} />
    </table>
  );
};
