import React, { JSX, useEffect } from 'react';

import { useAppSelector, useUrlSearch } from 'common/hooks';
import { isLoadingSelector } from 'store/reducers/supplier/product';
import { Search } from 'ui-kit';

import style from './OrderHeader.module.scss';

export const OrderHeader = (): JSX.Element => {
  const isLoading = useAppSelector(isLoadingSelector);

  const { value, handleChangeValue, handleRemoveValue, inputRef } = useUrlSearch({
    isDynamicSearch: true,
  });

  useEffect(() => {
    if (value) {
      inputRef.current?.focus();
    }
  }, [isLoading]);

  return (
    <div className={style.order_header}>
      <Search
        disabled={isLoading}
        placeholder="Search by Name"
        className={style.search}
        value={value}
        onChange={handleChangeValue}
        handleRemoveValue={handleRemoveValue}
        ref={inputRef}
      />
    </div>
  );
};
