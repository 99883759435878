import { useCallback } from 'react';

import cn from 'classnames';

import { ProductsPerPage } from 'elements';
import { useUpdateSearchParams } from 'pages/supplier-pages/pages/SupplierProducts/common/hoocks/useUpdateSearchParams';
import {
  DEFAULT_QUERY_PARAMS_FOR_URL,
  QUERY_PARAMS_KEY,
} from 'pages/supplier-pages/pages/SupplierProducts/common/utils/queryParamsConstants';
import { Pagination } from 'ui-kit/Pagination/Pagination';

import style from './PaginationControl.module.scss';

interface IPaginationControl {
  isLoading: boolean;
  totalItems: number;
  onPageChanged?: (page: number) => void;
  className?: string;
}

export const PaginationControl = ({
  isLoading,
  totalItems,
  onPageChanged,
  className,
}: IPaginationControl): JSX.Element => {
  const { updateUrlQueryParams, searchParams } = useUpdateSearchParams();
  const limitQueryParam = searchParams.get(QUERY_PARAMS_KEY.LIMIT);
  const pageQueryParam = searchParams.get(QUERY_PARAMS_KEY.PAGE);
  const limit = Number(limitQueryParam || DEFAULT_QUERY_PARAMS_FOR_URL.limit);
  const page = Number(pageQueryParam || DEFAULT_QUERY_PARAMS_FOR_URL.page);
  const totalPage = Math.ceil(totalItems / limit);

  const handleSetActivePage = (pageNumber: number): void => {
    updateUrlQueryParams([[QUERY_PARAMS_KEY.PAGE, pageNumber]]);

    onPageChanged?.(pageNumber);
  };

  const onChangeLimit = useCallback(
    (limit: number): void => {
      updateUrlQueryParams([
        [QUERY_PARAMS_KEY.PAGE, DEFAULT_QUERY_PARAMS_FOR_URL.page],
        [QUERY_PARAMS_KEY.LIMIT, limit],
      ]);
    },
    [updateUrlQueryParams],
  );

  const controlledValue = {
    label: { text: limitQueryParam || DEFAULT_QUERY_PARAMS_FOR_URL.limit },
    value: limitQueryParam || DEFAULT_QUERY_PARAMS_FOR_URL.limit,
  };

  return (
    <div className={cn(style.select_and_pagination_wrapper, className)}>
      <div className={style.inner_block_wrapper}>
        <ProductsPerPage
          disabled={isLoading}
          controlledValue={controlledValue}
          onChange={onChangeLimit}
        />
      </div>

      <Pagination
        disabled={isLoading}
        currentPage={page}
        totalPages={totalPage}
        onPageChanged={handleSetActivePage}
      />
    </div>
  );
};
