import React, { JSX } from 'react';

import cn from 'classnames';

import { OrderActionsMenu } from './OrderActions/OrderActionsMenu';
import { StatusFilter } from './StatusFilter/StatusFilter';

import { useAppSelector } from 'common/hooks';
import { headerCells } from 'pages/supplier-pages/pages/SupplierOrdersPage/common/utils/tableHeaderCells';
import { IColumns } from 'pages/supplier-pages/pages/SupplierProducts/common/types/types';
import { TableHeaderCell } from 'pages/supplier-pages/pages/SupplierProducts/ProductsList/ProductsTable/TableHeader/TableHeaderComponents/TableHeaderCell';
import { DateTimeContainer } from 'pages/supplier-pages/pages/SupplierProducts/ProductsList/ProductsTable/TableList/TableListComponents/DateTimeContainer';
import { TableListCell } from 'pages/supplier-pages/pages/SupplierProducts/ProductsList/ProductsTable/TableList/TableListComponents/TableListCell';
import { isLoadingSelector } from 'store/reducers/supplier/order';
import { IOrder } from 'store/reducers/supplier/order/types';

import style from './OrdersTable.module.scss';

interface IOrderTable {
  orders: IOrder[];
}

export const OrdersTable = ({ orders }: IOrderTable): JSX.Element => {
  const isLoading = useAppSelector(isLoadingSelector);

  const detailsOrder = (details: { amount: number; name: string }[]): string => {
    let res = '';

    details.forEach((item, index): void => {
      const lastLine = index === details.length - 1;

      res += `${item.amount} units "${item.name}"${lastLine ? '.' : ', '}`;
    });

    return res;
  };

  return (
    <div className={style.table_container}>
      <StatusFilter />
      {orders?.length ? (
        <table className={style.table}>
          <thead className={style.t_head}>
            <tr className={style.row}>
              {headerCells.map(column => (
                <TableHeaderCell
                  column={column as IColumns}
                  className={cn(style.header_cell, column.id > 2 && style.center)}
                  key={column.id}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {orders.map(order => (
              <tr key={order.id} className={style.row}>
                <td className={style.table_data}>
                  <DateTimeContainer
                    className={style.date}
                    created_at={order.created_at}
                  />
                </td>
                <TableListCell
                  className={style.table_data}
                  data={order.id}
                  columnName="order_number"
                />
                <TableListCell
                  className={style.table_data}
                  data={detailsOrder(order.details)}
                  columnName="detail"
                />
                <TableListCell
                  className={cn(style.table_data, style.center)}
                  data={`$${order.order_value}`}
                />
                <TableListCell
                  className={cn(style.table_data, style.center)}
                  data={order.status}
                />
                {/* <TableListCell */}
                {/*  className={cn(style.table_data, style.center)} */}
                {/*  data={new Date(order.created_at) */}
                {/*    .toLocaleDateString() */}
                {/*    .replace(/[/]/g, '.')} */}
                {/* /> */}
                {/* <TableListCell */}
                {/*  className={cn(style.table_data, style.center)} */}
                {/*  data={order} */}
                {/* /> */}
                <TableListCell
                  className={cn(style.table_data, style.center)}
                  data={<OrderActionsMenu id={order.id} />}
                />
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !isLoading && <h3 className={style.empty_list}>you don`t have any orders</h3>
      )}
    </div>
  );
};
