import React, {
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
  JSX,
  cloneElement,
  useEffect,
} from 'react';

import style from './DropdownMenu.module.scss';

interface IDropdownMenu {
  className?: string;
  children: ReactNode;
  trigger: ReactNode;
}

export const DropdownMenu = ({
  className,
  trigger,
  children,
}: IDropdownMenu): JSX.Element => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOutOfViewport, setIsOutOfViewport] = useState(false);

  useLayoutEffect(() => {
    if (open && menuRef.current) {
      const menuRect = menuRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      setIsOutOfViewport(menuRect.bottom > windowHeight);
    } else setIsOutOfViewport(false);
  }, [open]);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        setOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const enhancedTrigger = React.isValidElement(trigger)
    ? cloneElement(trigger as React.ReactElement, {
        onClick: () => setOpen(true),
      })
    : trigger;

  const styleMenu = isOutOfViewport ? { bottom: 0, top: 'unset' } : {};

  return (
    <>
      {open && (
        <div
          role="presentation"
          className={style.overlay}
          onClick={() => setOpen(false)}
        />
      )}
      <div className={style.container_menu}>
        {enhancedTrigger}
        {open && (
          <div
            ref={menuRef}
            role="presentation"
            className={`${style.menu} ${className}`}
            style={{ ...styleMenu }}
            onClick={e => e.stopPropagation()}
          >
            {children}
          </div>
        )}
      </div>
    </>
  );
};
