import React, { FC, JSX } from 'react';

import cn from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { ArrowIcon } from 'assets/icons';
import { deleteUrlSearchParams } from 'pages/supplier-pages/pages/SupplierProducts/common/utils/deleteUrlSearchParams';
import { ButtonIcon } from 'ui-kit';

import style from './FilterSwitcher.module.scss';

interface IFilterSwitcher {
  isShowFilters: boolean;
  setIsShowFilters: (value: boolean) => void;
  isLoading: boolean;
  onResetFilters?: () => void;
}

export const FilterSwitcher: FC<IFilterSwitcher> = ({
  isShowFilters,
  setIsShowFilters,
  isLoading,
  onResetFilters,
}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const text = isShowFilters ? 'Hide filters' : 'Show filters';

  const handleResetFilters = (): void => {
    deleteUrlSearchParams(searchParams, setSearchParams);
    onResetFilters?.();
  };

  const iconClasses = cn({
    [style.vector_down]: isShowFilters,
    [style.vector_up]: !isShowFilters,
  });

  return (
    <div className={style.wrapper}>
      <ButtonIcon
        className={style.rest_filters}
        type="button"
        onClick={() => setIsShowFilters(!isShowFilters)}
      >
        {text}
        <ArrowIcon className={iconClasses} />
      </ButtonIcon>

      <ButtonIcon
        disabled={isLoading}
        onClick={handleResetFilters}
        className={style.reset_link}
      >
        Reset Filters
      </ButtonIcon>
    </div>
  );
};
