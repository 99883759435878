import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { IAsyncThunkConfig } from 'common/types';
import { sellerService } from 'services/seller/seller.service';
import { ISellerGetCountryResponse } from 'services/seller/seller.serviceTypes';

export const getSellerCountry = createAsyncThunk<
  ISellerGetCountryResponse,
  void,
  IAsyncThunkConfig
>('sellerSettings/getSellerCountry', async (_, { rejectWithValue }) => {
  try {
    return await sellerService.getCountry();
  } catch (error) {
    const errorMessage =
      error instanceof AxiosError
        ? error.response?.data?.detail || error.message
        : '[getSellerCountry]: Error';

    return rejectWithValue(errorMessage);
  }
});
