import React, { JSX } from 'react';

import cn from 'classnames';

import { MoreIcon } from 'assets/icons';
import { Button, ButtonIcon } from 'ui-kit';
import { DropdownMenu } from 'ui-kit/DropdownMenu/DropdownMenu';

import style from './OrderActionsMenu.module.scss';

interface IOrderActions {
  id: number;
}

export const OrderActionsMenu = ({ id }: IOrderActions): JSX.Element => {
  return (
    <DropdownMenu
      className={style.order_action_menu}
      trigger={
        <ButtonIcon className={style.button_more}>
          <MoreIcon />
        </ButtonIcon>
      }
    >
      <Button
        color="white"
        onClick={() => {
          console.log(`edit order id: ${id}`);
        }}
        className={style.btn_action}
      >
        Edit
      </Button>
      <Button
        color="white"
        onClick={() => {
          console.log(`copy order id: ${id}`);
        }}
        className={style.btn_action}
      >
        Copy
      </Button>
      <hr className={style.separator} />
      <Button
        color="white"
        onClick={() => {
          console.log(`cancel order id: ${id}`);
        }}
        className={cn(style.btn_action, style.btn_cancel)}
      >
        Cancel order
      </Button>
    </DropdownMenu>
  );
};
