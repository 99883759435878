import React, { useState } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Search, Title } from 'ui-kit';
import { WhatsappLinkAsButton } from 'ui-kit/WhatsappLinkAsButton/WhatsappLinkAsButton';

import style from './OrderHistoryPage.module.scss';

type TabsType = 'All' | 'Preparing' | 'In progress' | 'Completed';
type OrderType = {
  number: string;
  date: string;
  status: TabsType;
  phone: string | null;
};

export const OrderHistoryPage = (): JSX.Element => {
  const statusTabsList: Array<TabsType> = [
    'All',
    'Preparing',
    'In progress',
    'Completed',
  ];
  const ordersInfo: Array<OrderType> = [
    {
      number: '4784437395989684',
      date: '20.12.2022',
      status: 'In progress',
      phone: '79845620588',
    },
    {
      number: '4784437395989684',
      date: '20.12.2022',
      status: 'In progress',
      phone: null,
    },
    {
      number: '4784437395989684',
      date: '20.12.2022',
      status: 'In progress',
      phone: '79845620588',
    },
    {
      number: '4784437395989684',
      date: '19.12.2022',
      status: 'In progress',
      phone: null,
    },
    {
      number: '4784437395989684',
      date: '18.12.2022',
      status: 'Completed',
      phone: '79845620588',
    },
  ];

  const [check, setCheck] = useState<TabsType>('All');
  const [ordersArray, setOrdersArray] = useState<Array<OrderType>>(ordersInfo);

  const handleClickButtonCategory = (tab: TabsType): void => {
    setCheck(tab);

    if (tab === 'All') setOrdersArray(ordersInfo);
    else {
      const filterOrders = ordersInfo.filter(order => tab === order.status);

      setOrdersArray(filterOrders);
    }
  };

  return (
    <div className={style.wrapper}>
      <div className={style.main}>
        <div className={style.head}>
          <Title>Order History</Title>
          <Search className={style.search} placeholder="Search by product name" />
        </div>

        <div className={style.tabs}>
          {statusTabsList.map(tab => (
            <button
              type="button"
              className={cn([style.tab], { [style.active]: tab === check })}
              onClick={() => handleClickButtonCategory(tab)}
              key={`status_${tab}`}
            >
              {tab}
            </button>
          ))}
        </div>

        {ordersArray.length ? (
          <ul className={style.list}>
            {ordersArray.map((order, index) => (
              <li key={index} className={style.item}>
                <div className={style.details}>
                  <div className={style.info}>
                    <p className={style.number}>Order No: {order.number}</p>
                    <p className={style.number}>Total: ...</p>
                    <p className={style.date}>{order.date}</p>
                  </div>
                  <div className={style.status}>{order.status}</div>

                  <div className={style.images}>
                    <div className={style.image} />
                    <div className={style.image} />
                    <div className={style.image} />
                    <div className={style.image} />
                  </div>
                </div>
                <div className={style.links}>
                  <Link to={order.number} className={style.link_details}>
                    View Details
                  </Link>
                  <WhatsappLinkAsButton
                    phoneNumber={order.phone}
                    text="Chat with supplier"
                  />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className={style.empty_message}>
            <p>Unfortunately, there are no orders.</p>
            <p>As soon as you place your first order it will appear here</p>
          </div>
        )}
      </div>
    </div>
  );
};
