import React, { FC } from 'react';

import { ReviewPhoto } from './ReviewPhoto/ReviewPhoto';

import { Grades } from 'elements';
import { IProductReviewsPhoto } from 'store/reducers/productSlice/types';

import style from './ProductReview.module.scss';

interface IProductReview {
  user_name: string;
  created_at: string;
  grade: number;
  text: string;
  photos?: IProductReviewsPhoto[];
}

export const ProductReview: FC<IProductReview> = ({
  user_name,
  created_at,
  grade,
  text,
  photos,
}): JSX.Element => {
  const date = new Date(created_at);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  return (
    <div className={style.product_review_container}>
      <div className={style.name_date_block}>
        <div className={style.name}>{user_name}</div>
        <div>{`${day} ${month} ${year}`}</div>
      </div>
      <Grades
        className={style.grades}
        variant="deals"
        fiveStar
        onlyStars
        grade={grade}
        count={5}
      />
      <div className={style.text}>{text}</div>
      <div className={style.photo}>
        {photos &&
          photos?.map(el => <ReviewPhoto key={el.id} image_url={el.image_url} />)}
      </div>
    </div>
  );
};
