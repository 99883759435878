import { JSX } from 'react';

import { OrdersList } from './OrderList/OrdersList';
import { OrderHeader } from './OrdersHeader/OrderHeader';

import style from './SupplierOrdersPage.module.scss';

export const SupplierOrdersPage = (): JSX.Element => {
  return (
    <section className={style.orders_wrapper}>
      <div className={style.orders_container}>
        <OrderHeader />
        <OrdersList />
      </div>
    </section>
  );
};
