import { FC } from 'react';

import cn from 'classnames';

import { TableHeaderCell } from './TableHeaderComponents/TableHeaderCell';

import { IColumns } from 'pages/supplier-pages/pages/SupplierProducts/common/types/types';

import style from './TableHeader.module.scss';

interface ITableHeader {
  tableSortData: IColumns[];
  className?: string;
}

export const TableHeader: FC<ITableHeader> = ({ tableSortData, className }) => {
  return (
    <thead>
      <tr className={cn(style.table_row, className)}>
        {tableSortData.map(column => (
          <TableHeaderCell key={column.id} column={column} className={style.table_head} />
        ))}
      </tr>
    </thead>
  );
};
