import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'typed-redux-saga';

import { favoritesReducer } from './favoritesSearchSlice';
import { appReducer } from './reducers/appSlice/slice';
import { authReducer } from './reducers/authSlice/slice';
import basketReducer from './reducers/basketSlice';
import categoryReducer from './reducers/categorySlice';
import { commonReducer } from './reducers/commonSlice/slice';
import filterReducer from './reducers/filterSlice';
import { popularProductsReducer } from './reducers/popularProducts';
import { productReducer } from './reducers/productSlice';
import { productOldReducer } from './reducers/productSliceOld';
import { sellerCartReducer } from './reducers/seller/cart/slice';
import { sellerProfileReducer } from './reducers/seller/profile/slice';
import { sellerSettingsReducer } from './reducers/seller/settings/slice';
import { supplierOrderReducer } from './reducers/supplier/order';
import { supplierOtherReducer } from './reducers/supplier/other/slice';
import { supplierProductReducer } from './reducers/supplier/product';
import { watchSupplierProductsList } from './reducers/supplier/product/sagas';
import { supplierProfileReducer } from './reducers/supplier/profile/slice';
import { userReducer } from './reducers/userSlice/slice';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    common: commonReducer,
    app: appReducer,
    auth: authReducer,
    user: userReducer,
    supplierProfile: supplierProfileReducer,
    supplierOther: supplierOtherReducer,
    sellerProfile: sellerProfileReducer,
    sellerSettings: sellerSettingsReducer,
    product: productReducer,
    supplierProduct: supplierProductReducer,
    supplierOrder: supplierOrderReducer,

    productListOld: productOldReducer,
    basket: basketReducer,
    filter: filterReducer,
    category: categoryReducer,
    popularProducts: popularProductsReducer,
    sellerCart: sellerCartReducer,
    favorites: favoritesReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootWatcher);

function* rootWatcher(): any {
  yield all([watchSupplierProductsList()]);
}

export type RootStateType = ReturnType<typeof store.getState>;
export type AppDispatchType = typeof store.dispatch;
