import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { IBrandsInfo } from './types';

import { productService } from 'services/product/product.service';
import { setResponseNotice } from 'store/reducers/appSlice/slice';

export const selectedProducts = createAsyncThunk<boolean, number[]>(
  'product/selectedProduct',
  async (productsIds: number[], { dispatch, rejectWithValue }) => {
    try {
      const res = await productService.restoreList(productsIds);

      dispatch(
        setResponseNotice({
          message: `${productsIds.length > 1 ? 'Products' : 'Product'} Activated Success`,
          noticeType: 'success',
        }),
      );

      return res;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.message);
      }

      return rejectWithValue('[deleteProducts]: ERROR');
    }
  },
);
export const unselectedProducts = createAsyncThunk<boolean, number[]>(
  'product/deleteProducts',
  async (selectedProductIds: number[], { dispatch, rejectWithValue }) => {
    try {
      const res = await productService.deleteProducts(selectedProductIds);

      dispatch(
        setResponseNotice({
          message: `${
            selectedProductIds.length > 1 ? 'Products' : 'Product'
          } Deactivated Success`,
          noticeType: 'success',
        }),
      );

      return res;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.message);
      }

      return rejectWithValue('[deleteProducts]: ERROR');
    }
  },
);

export const getBrandsInfo = createAsyncThunk<any, IBrandsInfo[]>(
  'product/getBrandsInfo',
  async (_, { rejectWithValue }) => {
    try {
      return await productService.getBrandsInfo();
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.message);
      }

      return rejectWithValue('[getBrandsInfo]: Error');
    }
  },
);
