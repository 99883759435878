import React from 'react';

import { useAppSelector } from 'common/hooks';
import { Advise } from 'pages/general-pages/ProductPage/components/Advise/Advise';
import {
  popularProductsSelector,
  productTagsSelector,
} from 'store/reducers/productSlice';

import style from './ProductRecommendations.module.scss';

export const ProductRecommendations = (): JSX.Element => {
  const popularProducts = useAppSelector(popularProductsSelector);
  const tags = useAppSelector(productTagsSelector);

  return (
    <>
      {popularProducts?.length > 0 && (
        <Advise
          products={popularProducts}
          title="Similar products in this category"
          className={style.popular_product}
        />
      )}

      {/* //todo ждет данные с бека */}
      {/* <div className={style.section}> */}
      {/* <LatestSearches tags={tags} /> */}
      {/* </div> */}
    </>
  );
};
