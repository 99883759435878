import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { IAsyncThunkConfig } from 'common/types';
import { getErrorMessageFromServer } from 'common/utils/getErrorMessageFromServer';
import { commonService } from 'services/common/common.service';
import {
  CountriesArrayType,
  ICategoryResponse,
  INumberEmployees,
} from 'services/common/common.serviceTypes';

export const getCountries = createAsyncThunk<CountriesArrayType, void, IAsyncThunkConfig>(
  'common/getCountries',
  async (_, { rejectWithValue }) => {
    try {
      return await commonService.getCountries();
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.message);
      }

      return rejectWithValue('[getCountries]: ERROR');
    }
  },
);

export const getCompanyNumberEmployees = createAsyncThunk<
  INumberEmployees[],
  void,
  IAsyncThunkConfig
>('common/getCompanyNumberEmployees', async (_, { rejectWithValue }) => {
  try {
    return await commonService.getCompanyNumberEmployees();
  } catch (error) {
    if (error instanceof AxiosError) {
      return rejectWithValue(error.message);
    }

    return rejectWithValue('[getCompanyNumberEmployees]: ERROR');
  }
});

export const getAllCategories = createAsyncThunk<
  ICategoryResponse[],
  void,
  IAsyncThunkConfig
>('category/getAllCategories', async (_, { rejectWithValue }) => {
  try {
    return await commonService.getAllCategories();
  } catch (error) {
    const errorMessage =
      error instanceof AxiosError
        ? getErrorMessageFromServer(error)
        : '[getAllCategories]: Error';

    return rejectWithValue(errorMessage);
  }
});
