import { FC } from 'react';

import { UploadImage } from 'elements';

import style from 'pages/supplier-pages/pages/SupplierProducts/ProductsList/AddNewProductPage/MainProductInfo/components/ImageContainer/ImageContainer.module.scss';

interface ILeaveReviewImage {
  id?: number;
  image?: string;
  uploadImage: (id: number, image: File) => void;
  deleteImage?: (id: number) => void;
}

export const LeaveReviewImage: FC<ILeaveReviewImage> = ({
  uploadImage,
  deleteImage,
  image,
  id,
}): JSX.Element => {
  const uploadImageHandler = (image: File): void => {
    if (id && image) {
      uploadImage(id, image);
    }
  };

  const deleteImageHandler = (): void => {
    if (id && deleteImage) {
      deleteImage(id);
    }
  };

  return (
    <div className={style.image_container}>
      <UploadImage
        image={image || ''}
        uploadImage={uploadImageHandler}
        deleteImage={deleteImageHandler}
        className={style.image}
        type="image_upload"
        label="Add photos of the product (optional)"
        description="Product image"
      />
      {!image && <span className={style.upload}>Upload</span>}
    </div>
  );
};
