import React, { FC, useState } from 'react';

import { UserDefaultProductImage } from 'assets/images';

interface IReviewPhoto {
  image_url: string;
}

export const ReviewPhoto: FC<IReviewPhoto> = ({ image_url }) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(image_url);
  const onError = (): void => setImgSrc(UserDefaultProductImage);

  return <img src={imgSrc || UserDefaultProductImage} alt="pic" onError={onError} />;
};
