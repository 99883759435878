import React, { JSX, useEffect } from 'react';

import cn from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { OrdersTable } from './OrdersTable/OrdersTable';

import { useAppDispatch, useAppSelector, useDebounce } from 'common/hooks';
import { PaginationControl } from 'elements';
import { useGetSearchParams } from 'pages/supplier-pages/pages/SupplierProducts/common/hoocks/useGetSearchParams';
import {
  isLoadingSelector,
  ordersSelector,
} from 'store/reducers/supplier/order/selectors';
import { getSupplierOrders } from 'store/reducers/supplier/order/thunks';
import { IOrderStatus } from 'store/reducers/supplier/order/types';
import { LoaderLinear } from 'ui-kit';

import style from './OrdersList.module.scss';

interface IOrderList {
  className?: string;
}

export const OrdersList = ({ className }: IOrderList): JSX.Element => {
  const isLoading = useAppSelector(isLoadingSelector);
  const ordersData = useAppSelector(ordersSelector);

  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { limit, page, sortField, sortBy, query } = useGetSearchParams();
  const offset = (page - 1) * limit;
  const searchValue = useDebounce(query);
  const status = searchParams.get('status') as IOrderStatus;

  useEffect(() => {
    dispatch(
      getSupplierOrders({
        limit,
        offset,
        status,
        sort: sortField,
        ascending: sortBy,
        query: searchValue,
      }),
    );
  }, [status, offset, limit, dispatch, sortField, sortBy, searchValue]);

  return (
    <div className={cn(style.order_list, className)}>
      {isLoading && <LoaderLinear />}
      <PaginationControl
        isLoading={isLoading}
        totalItems={ordersData.total_count}
        className={style.pagination}
      />
      <OrdersTable orders={ordersData.orders} />
      <PaginationControl
        isLoading={isLoading}
        totalItems={ordersData.total_count}
        className={style.pagination}
      />
    </div>
  );
};
