import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { LoadingStatusEnum } from 'common/types';
import Modal from 'elements/Modal';
import { AuthPageLayout } from 'pages/general-pages/auth-pages/assets';
import { ADD_NEW_EMAIL, CHANGE_EMAIL, HOME } from 'routes';
import { loadingSelector } from 'store/reducers/appSlice';
import { isAuthorizedSelector } from 'store/reducers/authSlice';
import { sendCodeForVerifyCurrentEmail } from 'store/reducers/authSlice/thunks';
import { Button } from 'ui-kit';
import { IVerifyForm, VerifyForm } from 'ui-kit/VerifyForm/VerifyForm';

import style from 'pages/general-pages/auth-pages/ChangeEmailPage/ChangeCurrentEmail/VerifyCurrentEmailPage/VerifyCurrentEmailPage.module.scss';

export const VerifyCurrentEmailPage = (): JSX.Element => {
  const isAuthorized = useAppSelector(isAuthorizedSelector);
  const navigate = useNavigate();
  const loading = useAppSelector(loadingSelector);
  const isLoading = loading === LoadingStatusEnum.Loading;
  const dispatch = useAppDispatch();
  const [isModalOpen, setOpenModal] = useState(false);
  const numberOfCharacters = 6;

  useEffect(() => {
    if (!isAuthorized) navigate(HOME);
  }, [isAuthorized]);

  const handleResentCode = (): void => {
    navigate(CHANGE_EMAIL);
  };

  const handleModalOnClose = (value: boolean): void => {
    setOpenModal(value);
  };

  const handleOnSubmitEmailVerifyCode = async (data: IVerifyForm): Promise<void> => {
    const verifyCodeString = Object.values(data).join('');

    if (verifyCodeString.length === numberOfCharacters) {
      const actionResult = await dispatch(
        sendCodeForVerifyCurrentEmail({ code: verifyCodeString }),
      );

      if (sendCodeForVerifyCurrentEmail.fulfilled.match(actionResult)) {
        navigate(ADD_NEW_EMAIL, { relative: 'path' });
      }
    }
  };

  return (
    <>
      <AuthPageLayout withHeader>
        <div className={style.header}>Verify current email</div>
        <div className={style.subheader}>Enter the code here</div>
        <VerifyForm
          handleOnSubmitVerifyCode={handleOnSubmitEmailVerifyCode}
          numberOfCharacters={numberOfCharacters}
        />
        <Button
          label="Resend code"
          type="button"
          disabled={isLoading}
          className={style.button_submit}
          onClick={handleResentCode}
        />
      </AuthPageLayout>

      <Modal showModal={isModalOpen} closeModal={handleModalOnClose}>
        <div className={style.modal_content_wrapper}>
          <div className={style.modal_header}>New code sent to your current e-mail</div>

          <Button
            className={style.modal_button}
            label="Okay"
            onClick={() => handleModalOnClose(false)}
          />
        </div>
      </Modal>
    </>
  );
};
