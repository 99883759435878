import React from 'react';

import { Navigate, RouteObject } from 'react-router-dom';

import { HOME, sellerRoutes, supplierRoutes } from 'routes/index';

/* Combines private routes from the sellerRoute and supplierRoute arrays and returns an array of private routes that navigate to login page */
export const convertCombinedPrivateRoutes = (): RouteObject[] => {
  const navigateToHome = <Navigate to={HOME} />;

  const flatRoutes = (routesArr: RouteObject[]): RouteObject[] => {
    return routesArr.flatMap(route =>
      route.children ? flatRoutes(route.children) : route,
    );
  };

  return [
    ...flatRoutes(supplierRoutes).map(route => ({
      path: route.path,
      element: navigateToHome,
    })),
    // .filter(route => route.path !== HOME),

    ...flatRoutes(sellerRoutes).map(route => ({
      path: route.path,
      element: navigateToHome,
    })),
  ];
};
