import { useState, JSX, useEffect } from 'react';

import { FilterBlock } from './FilterBlock/FilterBlock';

import { useAppDispatch, useAppSelector, useUrlSearch } from 'common/hooks';
import {
  isLoadingSelector,
  resetProductStatusSelection,
} from 'store/reducers/supplier/product';
import { Search, FilterSwitcher } from 'ui-kit';

import style from './ProductHeader.module.scss';

export const ProductHeader = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isLoadingSelector);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const { value, handleChangeValue, handleRemoveValue, inputRef } = useUrlSearch({
    isDynamicSearch: true,
  });

  useEffect(() => {
    if (value) {
      inputRef.current?.focus();
    }
  }, [isLoading]);

  const handleResetFilters = (): void => {
    dispatch(resetProductStatusSelection());
  };

  return (
    <div className={style.container}>
      <div className={style.inner_wrapper}>
        <Search
          disabled={isLoading}
          placeholder="Search by Name"
          className={style.search}
          value={value}
          onChange={handleChangeValue}
          handleRemoveValue={handleRemoveValue}
          ref={inputRef}
        />
        <FilterSwitcher
          isLoading={isLoading}
          isShowFilters={isShowFilters}
          setIsShowFilters={setIsShowFilters}
          onResetFilters={handleResetFilters}
        />
      </div>
      <FilterBlock isOpen={isShowFilters} />
    </div>
  );
};
