import React from 'react';

import { ProductsTable } from './ProductsTable/ProductsTable';
import { ProductTableEditor } from './ProductTableEditor/ProductTableEditor';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { PaginationControl } from 'elements';
import {
  hasPageChanged,
  isLoadingSelector,
  totalCountSelector,
} from 'store/reducers/supplier/product';
import { LoaderLinear } from 'ui-kit';

import style from './ProductsList.module.scss';

export const ProductsList = (): JSX.Element => {
  const isLoading = useAppSelector(isLoadingSelector);
  const dispatch = useAppDispatch();
  const totalItems = useAppSelector(totalCountSelector);
  const handleChangePage = (): void => {
    dispatch(hasPageChanged());
  };

  return (
    <>
      <PaginationControl
        isLoading={isLoading}
        totalItems={totalItems}
        onPageChanged={handleChangePage}
      />
      <div className={style.container}>
        {isLoading && <LoaderLinear />}
        <ProductTableEditor />
        <ProductsTable />
      </div>
      <PaginationControl
        isLoading={isLoading}
        totalItems={totalItems}
        onPageChanged={handleChangePage}
      />
    </>
  );
};
