import {
  ChangeEvent,
  KeyboardEvent,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  JSX,
} from 'react';

import cn from 'classnames';

import { CrossRedIcon } from 'assets/icons';
import { ButtonIcon } from 'ui-kit/buttons/ButtonIcon/ButtonIcon';

import styles from './Search.module.scss';

export interface ISearch
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'type'
  > {
  isPhotoSearch?: boolean;
  value?: string;
  handleRemoveValue?: () => void;
  onChangeValue?: (value: string) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleEnterKeyDown?: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export const Search = forwardRef<HTMLInputElement, ISearch>((props, ref): JSX.Element => {
  const {
    className,
    isPhotoSearch = false,
    disabled,
    value,
    onChange,
    handleRemoveValue,
    onChangeValue,
    handleEnterKeyDown,
    onKeyDown,
    ...restProps
  } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (onChangeValue) {
      onChangeValue(e.currentTarget.value);
    } else if (onChange) onChange(e);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      if (handleEnterKeyDown) handleEnterKeyDown();
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <div className={cn(styles.wrapper, className)}>
      <input
        className={styles.input}
        value={value}
        type="text"
        disabled={disabled}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...restProps}
        ref={ref}
      />

      {isPhotoSearch && (
        <label className={styles.button}>
          <input className={styles.photo_search} type="file" />
        </label>
      )}

      {value && value.length && (
        <ButtonIcon
          className={styles.button_cross}
          onClick={handleRemoveValue}
          disabled={disabled}
        >
          <CrossRedIcon className={styles.cross_icon} />
        </ButtonIcon>
      )}
    </div>
  );
});
