import { IStatus } from 'store/reducers/supplier/order/types';

export const statuses: IStatus[] = [
  { value: '', label: 'All' },
  { value: 'pending', label: 'Pending' },
  { value: 'awaiting_shipment', label: 'Awaiting Shipment' },
  { value: 'shipped', label: 'Shipped' },
  { value: 'awaiting_pickup', label: 'Awaiting Pickup' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'awaiting_refund', label: 'Awaiting Refund' },
  { value: 'accepted', label: 'Accepted' },
];
