import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { LoadingStatusEnum } from 'common/types';
import { getEmailValidationSchema } from 'common/utils';
import { AuthPageLayout } from 'pages/general-pages/auth-pages/assets';
import { HOME, VERIFY_NEW_EMAIL } from 'routes';
import {
  IChangeCurrentEmailRequest,
  IChangeNewEmailRequest,
} from 'services/auth/auth.serviceTypes';
import { loadingSelector } from 'store/reducers/appSlice';
import { isAuthorizedSelector } from 'store/reducers/authSlice';
import { requestCodeForNewEmail } from 'store/reducers/authSlice/thunks';
import { Button, Input } from 'ui-kit';

import style from 'pages/general-pages/auth-pages/ChangeEmailPage/ChangeCurrentEmail/ChangeEmailPage.module.scss';

const schema = yup
  .object({
    new_email: getEmailValidationSchema(),
  })
  .required();

export const AddNewEmailPage = (): JSX.Element => {
  const navigate = useNavigate();
  const isAuthorized = useAppSelector(isAuthorizedSelector);
  const dispatch = useAppDispatch();
  const loading = useAppSelector(loadingSelector);
  const isLoading = loading === LoadingStatusEnum.Loading;

  useEffect(() => {
    if (!isAuthorized) navigate(HOME);
  }, [isAuthorized]);

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<IChangeCurrentEmailRequest & IChangeNewEmailRequest>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = async (data: IChangeNewEmailRequest): Promise<void> => {
    const actionResult = await dispatch(
      requestCodeForNewEmail({ new_email: data.new_email }),
    );

    if (requestCodeForNewEmail.fulfilled.match(actionResult)) {
      navigate(VERIFY_NEW_EMAIL, { relative: 'path' });
    }
  };

  return (
    <AuthPageLayout withHeader>
      <div className={style.header}>Change email</div>
      <div className={style.subheader}>
        Enter your <b>new</b> email address
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
        <Input
          {...register('new_email')}
          placeholder="New email"
          classNameWrapper={style.input_wrapper}
          error={errors.new_email?.message}
          disabled={isLoading}
        />

        <Button
          label="Continue"
          type="submit"
          disabled={!isValid || isLoading}
          className={style.button_submit}
        />
      </form>
    </AuthPageLayout>
  );
};
