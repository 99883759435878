import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { LoadingStatusEnum } from 'common/types';
import Modal from 'elements/Modal';
import { AuthPageLayout } from 'pages/general-pages/auth-pages/assets';
import { ADD_NEW_EMAIL, HOME, PERSONAL_ACCOUNT } from 'routes';
import { loadingSelector } from 'store/reducers/appSlice';
import { isAuthorizedSelector, userRoleSelector } from 'store/reducers/authSlice';
import { sendCodeForVerifyNewEmail } from 'store/reducers/authSlice/thunks';
import { Button } from 'ui-kit';
import { IVerifyForm, VerifyForm } from 'ui-kit/VerifyForm/VerifyForm';

import style from 'pages/general-pages/auth-pages/ChangeEmailPage/AddNewEmail/VerifyNewEmailPage/VerifyNewEmailPage.module.scss';

export const VerifyNewEmailPage = (): JSX.Element => {
  const isAuthorized = useAppSelector(isAuthorizedSelector);
  const navigate = useNavigate();
  const loading = useAppSelector(loadingSelector);
  const userRole = useAppSelector(userRoleSelector);
  const dispatch = useAppDispatch();
  const isLoading = loading === LoadingStatusEnum.Loading;
  const [isModalOpen, setOpenModal] = useState(false);
  const numberOfCharacters = 6;

  useEffect(() => {
    if (!isAuthorized) navigate(HOME);
  }, [isAuthorized]);

  const handleResentCode = (): void => {
    navigate(ADD_NEW_EMAIL);
  };

  const handleModalOnClose = (value: boolean): void => {
    setOpenModal(value);
    if (userRole === 'seller') {
      navigate(PERSONAL_ACCOUNT);
    } else {
      navigate(HOME);
    }
  };

  const handleOnSubmitEmailVerifyCode = async (data: IVerifyForm): Promise<void> => {
    const verifyCodeString = Object.values(data).join('');

    if (verifyCodeString.length === numberOfCharacters) {
      const actionResult = await dispatch(
        sendCodeForVerifyNewEmail({ code: verifyCodeString }),
      );

      if (sendCodeForVerifyNewEmail.fulfilled.match(actionResult)) {
        setOpenModal(true);
      }
    }
  };

  return (
    <>
      <AuthPageLayout withHeader>
        <div className={style.header}>Verify new email</div>
        <div className={style.subheader}>Enter the code here</div>
        <VerifyForm
          handleOnSubmitVerifyCode={handleOnSubmitEmailVerifyCode}
          numberOfCharacters={numberOfCharacters}
        />
        <Button
          label="Resend code"
          type="button"
          disabled={isLoading}
          className={style.button_submit}
          onClick={handleResentCode}
        />
      </AuthPageLayout>

      <Modal showModal={isModalOpen} closeModal={handleModalOnClose}>
        <div className={style.modal_content_wrapper}>
          <div className={style.modal_header}>
            Your new email has been successfully saved
          </div>

          <Button
            className={style.modal_button}
            label="Okay"
            onClick={() => handleModalOnClose(false)}
          />
        </div>
      </Modal>
    </>
  );
};
