import React, { useCallback, useEffect, useState } from 'react';

import CheckoutAndCartWithOrder from './CheckoutAndCartWithOrder/CheckoutAndCartWithOrder';
import { EmptyCart } from './EmptyCart';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { getSellerCartData, totalItems } from 'store/reducers/seller/cart';
import { isLoading } from 'store/reducers/seller/cart/selectors';
import { LoaderLinear } from 'ui-kit';

import style from './SellerCart.module.scss';

export const SellerCartPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const cartIsLoading = useAppSelector(isLoading);
  const totalAmountItems = useAppSelector(totalItems);
  const [cartIsFetching, setCartIsFetching] = useState(false);

  const getCartData = useCallback(async (): Promise<void> => {
    setCartIsFetching(true);
    await dispatch(
      getSellerCartData({
        offset: 0,
        limit: 100,
      }),
    );
    setCartIsFetching(false);
  }, [dispatch]);

  useEffect(() => {
    getCartData();
  }, [getCartData]);

  if (cartIsLoading || cartIsFetching) return <LoaderLinear />;

  return (
    <div className={style.wrapper}>
      {totalAmountItems ? <CheckoutAndCartWithOrder /> : <EmptyCart />}
    </div>
  );
};
