import axios from 'axios';

import { getCookie } from 'common/utils';
import { store } from 'store/createStore';
import { setResponseError } from 'store/reducers/appSlice/slice';

export const baseConfigService = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || 'http://localhost/',
  withCredentials: true,
  headers: {
    'X-CSRF-Token': getCookie('csrf_access_token') || '',
  },
});

baseConfigService.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status >= 500) {
      store.dispatch(
        setResponseError(
          'Internal Server Error. Something went wrong on our end. Please try again later.',
        ),
      );

      return Promise.reject();
    }
    if (error.code === 'ERR_NETWORK') {
      store.dispatch(
        setResponseError(
          'No internet connection. Please check your network and try again.',
        ),
      );

      return Promise.reject();
    }
    if (error.response.data.detail === 'string') {
      store.dispatch(setResponseError(error.response.data.detail));
    }
    if (error.response.data.detail.constructor === Array) {
      store.dispatch(setResponseError(error.response.data.detail[0].msg));
    }

    return Promise.reject(error);
  },
);
