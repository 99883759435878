import {
  ChangeEvent,
  KeyboardEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

interface ISearchHandlerReturnType {
  value: string;
  handleChangeValue: (e: ChangeEvent<HTMLInputElement>) => void;
  handleRemoveValue: () => void;
  handleEnterKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  inputRef: RefObject<HTMLInputElement>;
}

interface ISearchHandler {
  isDynamicSearch?: boolean;
}

export const useUrlSearch = ({
  isDynamicSearch,
}: Partial<ISearchHandler> = {}): ISearchHandlerReturnType => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeValue = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  }, []);

  const handleRemoveValue = useCallback((): void => {
    setSearchValue('');
    searchParams.delete('query');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleEnterKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>): void => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        const trimmedSearchValue = searchValue.trim();

        if (trimmedSearchValue) {
          searchParams.set('query', trimmedSearchValue);
        } else {
          searchParams.delete('query');
        }

        const path = `${location.pathname}?${searchParams.toString()}`;

        navigate(path);
      }
    },
    [searchValue, location.pathname, searchParams, navigate],
  );

  useEffect(() => {
    const params = searchParams.get('query');

    if (params) {
      setSearchValue(params);
    }
  }, []);

  useEffect(() => {
    if (isDynamicSearch) {
      if (searchValue.trim()) {
        searchParams.set('query', searchValue);
      } else {
        searchParams.delete('query');
      }
      const path = `${location.pathname}?${searchParams.toString()}`;

      navigate(path);
    }
  }, [searchValue, searchParams]);

  return {
    value: searchValue,
    handleChangeValue,
    handleRemoveValue,
    handleEnterKeyDown,
    inputRef,
  };
};
