import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'common/hooks';
import { AuthPageLayout } from 'pages/general-pages/auth-pages/assets';
import { DeleteAccountForm } from 'pages/general-pages/auth-pages/DeleteAccountPage/DeleteAccountForm/DeleteAccountForm';
import { HOME } from 'routes';
import { isAuthorizedSelector } from 'store/reducers/authSlice';

import style from './DeleteAccountPage.module.scss';

export const DeleteAccountPage = (): JSX.Element => {
  const navigate = useNavigate();
  const isAuthorized = useAppSelector(isAuthorizedSelector);

  useEffect(() => {
    if (!isAuthorized) navigate(HOME);
  }, [isAuthorized]);

  return (
    <AuthPageLayout withHeader>
      <div className={style.header}>Delete account</div>
      <div className={style.subheader}>Enter your password</div>
      <DeleteAccountForm />
    </AuthPageLayout>
  );
};
