import { JSX } from 'react';

import { useSearchParams } from 'react-router-dom';

import { statuses } from 'pages/supplier-pages/pages/SupplierOrdersPage/common/utils/orderFilters';
import { ISelectOption, Select } from 'ui-kit';

import style from './StatusFilter.module.scss';

export const StatusFilter = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = (selectOption: ISelectOption): void => {
    if (!selectOption.value) {
      const newSearchParams = new URLSearchParams();

      setSearchParams(newSearchParams);
    } else setSearchParams(`status=${selectOption.value}`);
  };

  return (
    <Select
      options={statuses.map(el => ({ ...el, label: { text: el.label } }))}
      className={style.select_filter}
      classNameMenu={style.select_menu}
      onChange={handleClick}
    />
  );
};
