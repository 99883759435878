import { createSlice } from '@reduxjs/toolkit';

import { getSupplierOrders } from './thunks';
import { IOrderResponse } from './types';

const initialState = {
  orders: {} as IOrderResponse,
  isLoading: false,
};

const supplierOrderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSupplierOrders.pending, state => {
        state.isLoading = true;
      })
      .addCase(getSupplierOrders.fulfilled, (state, action) => {
        state.orders = action.payload;
        state.isLoading = false;
      })
      .addCase(getSupplierOrders.rejected, state => {
        state.isLoading = false;
      });
  },
});

export const supplierOrderReducer = supplierOrderSlice.reducer;
