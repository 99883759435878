import React, { FC, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useAppSelector } from 'common/hooks';
import { LoadingStatusEnum } from 'common/types';
import { loadingSelector } from 'store/reducers/appSlice';
import { Input } from 'ui-kit/index';

import style from 'ui-kit/VerifyForm/VerifyForm.module.scss';

const INPUT_FIELD_NAME = 'character';

interface IVerifyFormComponent {
  handleOnSubmitVerifyCode: (data: IVerifyForm) => void;
  numberOfCharacters?: number;
  maxCharacters?: number;
  inputMode?:
    | 'search'
    | 'numeric'
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'decimal'
    | undefined;
}

export interface IVerifyForm {
  [index: string]: string;
}

export const VerifyForm: FC<IVerifyFormComponent> = ({
  handleOnSubmitVerifyCode,
  numberOfCharacters = 6,
  maxCharacters = 1,
  inputMode = 'numeric',
}) => {
  const { register, handleSubmit, setFocus, setValue, getValues } =
    useForm<IVerifyForm>();
  const loading = useAppSelector(loadingSelector);
  const isLoading = loading === LoadingStatusEnum.Loading;

  const numberOfInputs = (num: number): number[] => {
    const arr = [];

    for (let i = 1; i <= num; i += 1) {
      arr.push(i);
    }

    return arr;
  };

  useEffect(() => {
    setFocus(INPUT_FIELD_NAME + arrayOfInputs[0]);
  }, []);

  const arrayOfInputs = numberOfInputs(numberOfCharacters);

  const onHandleSubmit = (data: IVerifyForm): void => {
    handleOnSubmitVerifyCode(data);
    setFocus(INPUT_FIELD_NAME + (Object.values(data).join('').length + 1));
  };

  const onHandlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    const data = e?.clipboardData.getData('text/plain');
    const arrayOfCharacters = data?.replace(/\s+/g, '').split('');

    for (let i = 1; i <= numberOfCharacters; i += 1) {
      if (arrayOfCharacters) {
        setValue(INPUT_FIELD_NAME + i, arrayOfCharacters[i - 1]);
      }
    }

    const payload = getValues();

    onHandleSubmit(payload);
  };

  return (
    <div className={style.card}>
      <form className={style.form}>
        {arrayOfInputs.map(item => (
          <Input
            {...register(INPUT_FIELD_NAME + item, {
              onChange: handleSubmit(onHandleSubmit),
            })}
            inputMode={inputMode}
            key={item}
            classNameWrapper={style.input_wrapper}
            disabled={isLoading}
            className={style.input_item}
            maxLength={maxCharacters}
            onPaste={onHandlePaste}
          />
        ))}
      </form>
    </div>
  );
};
