import React, { FC } from 'react';

import cn from 'classnames';

import { Stars } from 'ui-kit';
import { Star } from 'ui-kit/Stars/Star/Star';

import style from './Grades.module.scss';

interface IGradeProps {
  grade: string | number;
  fiveStar?: boolean;
  onlyStars?: boolean;
  variant?: 'deals' | 'reviews';
  count: number | null;
  className?: string;
  starSize?: string;
  starsWithCustomText?: boolean;
  customText?: string;
  setStars?: (starsCount: number) => void;
  setSelectedStars?: (selectedStarsCount: number) => void;
}

export const Grades: FC<IGradeProps> = ({
  grade,
  fiveStar = false,
  onlyStars = false,
  variant = 'reviews',
  count,
  className,
  starSize = '24',
  starsWithCustomText = false,
  customText,
  setStars,
  setSelectedStars,
}): JSX.Element => {
  const containerMode = {
    [`${style.is_gap}`]: variant === 'reviews' && fiveStar,
  };
  const textContainerMode = {
    [`${style.deals}`]: variant === 'deals',
  };

  const percentage = (+grade * 100) / 5;

  return (
    <div className={cn(style.container, containerMode, className)}>
      {fiveStar ? (
        <Stars
          setSelectedStars={setSelectedStars}
          setStars={setStars}
          reward={+grade}
          sizes={starSize}
        />
      ) : (
        <Star percent={`${percentage}%`} sizes={starSize} />
      )}
      {!onlyStars && (
        <div className={cn(style.text_container, textContainerMode)}>
          <p>{grade}</p>
          {variant === 'reviews' && <p>/</p>}
          <p>
            {count} {variant === 'reviews' ? 'reviews' : 'Deals'}
          </p>
        </div>
      )}
      {starsWithCustomText && (
        <div className={cn(style.text_container, textContainerMode)}>
          <span>{customText}</span>
        </div>
      )}
    </div>
  );
};
