import React, { FC, useState } from 'react';

import cn from 'classnames';

import { Grades } from 'elements';
import { IProductReviewsGrades } from 'store/reducers/productSlice/types';
import { Button, Checkbox } from 'ui-kit';

import style from './ProductReviewFilters.module.scss';

interface IProductReviewFilters {
  onWithPhotosFilter: () => void;
  onStarsFilterClick: (grade: number, reviewCount: number) => void;
  reviewsCurrentGrades: number | null;
  productReviewGrades: IProductReviewsGrades;
  withPhotos: boolean | null;
}

export const ProductReviewFilters: FC<IProductReviewFilters> = ({
  onWithPhotosFilter,
  onStarsFilterClick,
  reviewsCurrentGrades,
  productReviewGrades,
  withPhotos,
}): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const onChangeHandler = (): void => {
    setIsChecked(!isChecked);
    onWithPhotosFilter();
  };

  return (
    <>
      <Grades
        className={style.grades}
        grade={productReviewGrades.grade_average}
        count={productReviewGrades.review_count}
        starSize="40"
      />
      <div className={style.filters}>
        <div className={style.filter_buttons}>
          {productReviewGrades.details.map((el, index) => (
            <Button
              key={index}
              className={cn(
                style.filter_button,
                reviewsCurrentGrades === el.grade_overall ? style.active_button : '',
              )}
              onClick={() => onStarsFilterClick(el.grade_overall, el.review_count)}
            >
              <Grades
                className={style.grades}
                onlyStars
                fiveStar
                grade={el.grade_overall}
                count={el.review_count}
                starSize="16"
                starsWithCustomText
                customText={`(${String(el.review_count)})`}
              />
            </Button>
          ))}
        </div>
        <div className={style.filter_checkbox}>
          <Checkbox
            checked={withPhotos || false}
            variant="default"
            className={style.checkbox}
            onChange={onChangeHandler}
          />
          <span className={style.checkBoxText}>Show only reviews with photos</span>
        </div>
      </div>{' '}
    </>
  );
};
