import React from 'react';

import { Banner3Image } from 'assets/images';
import { LazyImage } from 'elements/LazyImage/LazyImage';
import { SocialNetworks, WhatsappPhoneNumber, Title } from 'ui-kit';

import style from './ContactSupport.module.scss';

export const ContactSupportPage = (): JSX.Element => {
  return (
    <div>
      <div className={style.image_container}>
        <LazyImage src={Banner3Image} alt="" type="default_image" />
      </div>

      <div className={style.content_container}>
        <Title className={style.title}>Got any questions? Contact us via WhatsApp</Title>

        <div className={style.contacts}>
          <WhatsappPhoneNumber />
        </div>

        <SocialNetworks variant="m" />
      </div>
    </div>
  );
};
