import React from 'react';

import { RouteObject } from 'react-router-dom';

import { CHECKOUT_ERROR } from './path-constants/seller';

import {
  CART,
  CHECKOUT_SUCCESS,
  FAVORITES,
  HELP,
  HOME,
  ORDER_HISTORY,
  ORDER_HISTORY_DETAILS,
  PERSONAL_ACCOUNT,
} from '.';

import { Layout } from 'layouts';
import {
  CheckoutErrorPage,
  CheckoutSuccessPage,
  OrderDetailsPage,
  OrderHistoryPage,
  SellerCartPage,
  SellerFavoritesList,
  SellerProfilePage,
} from 'pages/seller-pages';

export const sellerRoutes: RouteObject[] = [
  {
    path: HOME,
    element: <Layout />,
    children: [
      {
        path: CHECKOUT_SUCCESS,
        element: <CheckoutSuccessPage />,
      },
      {
        path: ORDER_HISTORY,
        element: <OrderHistoryPage />,
      },
      {
        path: ORDER_HISTORY_DETAILS,
        element: <OrderDetailsPage />,
      },
      {
        path: FAVORITES,
        element: <SellerFavoritesList />,
      },
      {
        path: CART,
        element: <SellerCartPage />,
      },
      {
        path: PERSONAL_ACCOUNT,
        element: <SellerProfilePage />,
      },
    ],
  },
  {
    path: HOME,
    element: <Layout headerVariant="additional" />,
    children: [
      {
        path: CHECKOUT_ERROR,
        element: <CheckoutErrorPage />,
      },
    ],
  },
  {
    path: HELP,
    element: <p> Help </p>,
  },
];
