import { createSlice } from '@reduxjs/toolkit';

import { getSellerCountry } from './thunks';

import { ICountry } from 'services/common/common.serviceTypes';

interface IState {
  currency: string;
  shippingCountry: ICountry;
}

const initialState: IState = {
  currency: '',
  shippingCountry: {
    country: 'Azerbaijan',
    country_code: '994',
    country_short: 'az',
    created_at: '2024-09-13T08:27:07.818031',
    currency: 'AZN',
    flag: '',
    id: 1,
    updated_at: '2024-09-13T08:27:07.818031',
  },
};

const sellerSettingsSlice = createSlice({
  name: 'sellerSettings',
  initialState,
  reducers: {
    changeShippingCountry: (state, action) => {
      state.shippingCountry = action.payload;
    },
    changeCurrency: (state, action) => {
      state.currency = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getSellerCountry.fulfilled, (state, action) => {
      if (action.payload && action.payload.country) {
        state.shippingCountry = action.payload.country;
      }
    });
  },
});

export const sellerSettingsReducer = sellerSettingsSlice.reducer;
export const { changeShippingCountry, changeCurrency } = sellerSettingsSlice.actions;
