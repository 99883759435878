export const {
  PRODUCT_DETAILS,
  PRODUCT_ID,
  PRODUCT_REVIEWS,
  PERSONAL_ACCOUNT,
  CART,
  CHECKOUT,
  CHECKOUT_ERROR,
  CHECKOUT_SUCCESS,
  ORDER_HISTORY,
  ORDER_HISTORY_DETAILS,
  FAVORITES,
  HELP,
} = {
  PRODUCT_DETAILS: '/product',
  PRODUCT_ID: ':productId',
  PRODUCT_REVIEWS: '/product_reviews',
  PERSONAL_ACCOUNT: '/personal_account',
  CART: '/cart',
  CHECKOUT: '/checkout',
  CHECKOUT_SUCCESS: '/checkout_success',
  CHECKOUT_ERROR: '/checkout_error',
  ORDER_HISTORY: '/order_history',
  ORDER_HISTORY_DETAILS: '/order_history/4784437395989684',
  FAVORITES: '/favorites',
  HELP: '/help',
} as const;
