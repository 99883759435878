import React, { FC, useId } from 'react';

export interface IStar {
  percent: string;
  sizes?: string;
  setStars?: (starsCount: number) => void;
  setSelectedStars?: (selectedStarsCount: number) => void;
  starsCount?: number;
}

export const Star: FC<IStar> = ({
  percent,
  sizes,
  setStars,
  starsCount,
  setSelectedStars,
}) => {
  const uniqueId = useId();

  const onMouseOverHandler = (): void => {
    if (setStars && starsCount) setStars(starsCount);
  };

  const onMouseLeaveHandler = (): void => {
    if (setStars) setStars(0);
  };

  const onClickHandler = (): void => {
    if (setSelectedStars && starsCount) setSelectedStars(starsCount);
  };

  return (
    <svg
      width={sizes || '16'}
      height={sizes || '15'}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={onMouseOverHandler}
      onMouseLeave={onMouseLeaveHandler}
      onClick={onClickHandler}
    >
      <path
        d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
        fill={`url('#StarGradient${uniqueId}')`}
      />
      <linearGradient id={`StarGradient${uniqueId}`}>
        <stop stopColor="#FFAB5E" />
        <stop offset={percent} stopColor="#FFAB5E" />
        <stop offset={percent} stopColor="#B6B6B6" />
        <stop offset="100%" stopColor="#B6B6B6" />
      </linearGradient>
    </svg>
  );
};
