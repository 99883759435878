export const headerCells = [
  {
    id: 0,
    name: 'Creation date',
    isSortable: true,
    sortValue: 'date',
  },
  {
    id: 1,
    name: 'Order number',
    isSortable: true,
    sortValue: 'order_number',
  },
  {
    id: 2,
    name: 'Detail',
  },
  {
    id: 3,
    name: 'Order value',
    isSortable: true,
    sortValue: 'order_value',
  },
  {
    id: 4,
    name: 'Status',
    isSortable: true,
    sortValue: 'status',
  },
  // {
  //   id: 5,
  //   name: 'Shipping date',
  //   isSortable: true,
  // },
  // {
  //   id: 6,
  //   name: 'Tracking number',
  //   isSortable: true,
  // },
  {
    id: 7,
    name: 'In more detail',
  },
];
