import React from 'react';

import { useNavigate } from 'react-router-dom';

import { HOME, ORDER_HISTORY } from 'routes';
import { Button } from 'ui-kit';
import { WhatsappLinkAsButton } from 'ui-kit/WhatsappLinkAsButton/WhatsappLinkAsButton';

import style from './CheckoutSuccessPage.module.scss';

export const CheckoutSuccessPage = (): JSX.Element => {
  const navigate = useNavigate();
  const navigateToHome = (): void => {
    return navigate(HOME);
  };
  const navigateToOrderHistory = (): void => {
    return navigate(ORDER_HISTORY);
  };

  return (
    <div className={style.container}>
      <div className={style.container_header}>
        <span className={style.title}>
          The order has been successfully placed and paid! <br />
          You can track progress in the Order History section.
        </span>
        <div className={style.container_button}>
          <WhatsappLinkAsButton
            phoneNumber="99999999"
            text="Chat with supplier"
            className={style.button_whatsapp}
          />
          <div className={style.buttons}>
            <Button
              onClick={navigateToHome}
              className={style.button_red}
              label="Go to Main Page"
            />
            <Button
              onClick={navigateToOrderHistory}
              className={style.button_back}
              label="Go to Order History"
            />
          </div>
        </div>
      </div>
      <div className={style.container_info}>
        <span className={style.info_text}>You may also like</span>
      </div>
    </div>
  );
};
