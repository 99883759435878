import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { IOrderRequestParams, IOrderResponse } from './types';

import { supplierService } from 'services';

export const getSupplierOrders = createAsyncThunk<IOrderResponse, IOrderRequestParams>(
  'order/getOrders',

  async (params, { rejectWithValue }) => {
    try {
      const res = await supplierService.getOrders(params);

      return res.result;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.message);
      }

      return rejectWithValue('[getOrders]: ERROR');
    }
  },
);
