import { FC, useEffect, useState } from 'react';

import cn from 'classnames';

import { ILocationAndCurrencySelection } from './LocationAndCurrencySelection.props';

import { ArrowIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { useOnClickOutside } from 'common/hooks/useOnClickOutside';
import { COUNTRY_FLAGS } from 'common/utils';
import { countriesSelector, getCountries } from 'store/reducers/commonSlice';
import {
  sellerShippingCountrySelector,
  changeShippingCountry,
  getSellerCountry,
} from 'store/reducers/seller/settings';
import { ISelectOption, Select } from 'ui-kit';

import style from './LocationAndCurrencySelection.module.scss';

const CURRENCY_DATA: ISelectOption[] = [
  { label: { text: 'Russian / RUB' }, value: 'ru' },
  { label: { text: 'English / USD' }, value: 'usd' },
];

export const LocationAndCurrencySelection: FC<ILocationAndCurrencySelection> = ({
  className,
  dropOnUp = false,
  isMobileView,
  wrapperClassName,
}) => {
  const dispatch = useAppDispatch();
  const countries = useAppSelector(countriesSelector);
  const shippingCountry = useAppSelector(sellerShippingCountrySelector);
  const [countriesData, setCountriesData] = useState<ISelectOption[]>([]);
  const [isOpenOnMobile, setOpenOnMobile] = useState(false);

  const containerClasses = cn(style.container, className, {
    [style.mobile]: isMobileView,
    [style.show]: isMobileView && isOpenOnMobile,
  });

  const handleToggleOpenOnMobile = (): void => {
    setOpenOnMobile(prev => !prev);
  };

  const refObj = useOnClickOutside(setOpenOnMobile);

  const handleSelect = (value: ISelectOption): void => {
    dispatch(changeShippingCountry(countries.find(el => el.id === +value.value)));
  };

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getSellerCountry());
  }, [dispatch]);

  useEffect(() => {
    if (!countriesData.length) {
      setCountriesData(
        countries.map(el => ({
          label: { text: el.country, image_src: el.flag },
          value: el.id,
        })),
      );
    }
  }, [countries, countriesData.length]);

  return (
    <div ref={refObj} className={cn(style.wrapper, wrapperClassName)}>
      {isMobileView && (
        <div onClickCapture={handleToggleOpenOnMobile} className={style.mobile_header}>
          <div className={style.currency}>
            {CURRENCY_DATA[0].label.text.substring(
              CURRENCY_DATA[0].label.text.length - 3,
            )}
          </div>

          <img width="20px" src={COUNTRY_FLAGS[shippingCountry.id]} alt="flag" />

          <ArrowIcon className={cn({ [style.arrow_up]: isOpenOnMobile })} width="14" />
        </div>
      )}

      <div className={containerClasses}>
        <Select
          dropOnUp={dropOnUp}
          options={CURRENCY_DATA}
          width="172px"
          header
          className={style.select}
        />

        <div className={style.select_box}>
          <span className={style.select_title}>Ship to</span>
          {countriesData.length && (
            <Select
              onChange={handleSelect}
              dropOnUp={dropOnUp}
              defaultValue={shippingCountry.id}
              options={countriesData}
              header
              width="180px"
              className={style.select}
            />
          )}
        </div>
      </div>
    </div>
  );
};
