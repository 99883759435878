import React, { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ArrowToLeft, ArrowToRight } from 'assets/icons';
import { UserDefaultProductImage } from 'assets/images';
import { PRODUCT_DETAILS } from 'routes';
import { Star } from 'ui-kit/Stars/Star/Star';

import style from './ProductTitle.module.scss';

interface IProductTitle {
  productTitle: string;
  productImageUrl: string;
  gradeAverage: number | null;
  companyName: string;
  productId: string | undefined;
}

export const ProductReviewTitle: FC<IProductTitle> = ({
  companyName,
  productImageUrl,
  gradeAverage,
  productTitle,
  productId,
}): JSX.Element => {
  const navigate = useNavigate();
  const percentageGrade: string = gradeAverage
    ? `${String((gradeAverage * 100) / 5)}%`
    : '0%';
  const [imgSrc, setImgSrc] = useState<string | undefined>(productImageUrl);
  const onError = (): void => setImgSrc(UserDefaultProductImage);
  const pathToProduct = `${PRODUCT_DETAILS}/${productId}`;

  return (
    <div className={style.product_container_wrapper}>
      <div className={style.product_container}>
        <ArrowToLeft
          className={style.arrow}
          onClick={() => {
            navigate(pathToProduct);
          }}
        />
        <img
          className={style.product_image}
          src={imgSrc || UserDefaultProductImage}
          alt="pic"
          onError={onError}
        />
        <div className={style.product_text}>
          <div className={style.product_title}>{productTitle}</div>
          <div className={style.supplier_info}>
            <Star percent={percentageGrade} />
            <div className={style.grade}>{gradeAverage}</div>
            <div className={style.company_name}>{companyName}</div>
            <ArrowToRight />
          </div>
        </div>
      </div>
    </div>
  );
};
