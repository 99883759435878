import React, { FC, useState } from 'react';

import { useForm } from 'react-hook-form';

import { LeaveReviewImage } from './LeaveReviewImage/LeaveReviewImage';

import { useAppDispatch } from 'common/hooks';
import { convertImageToBase64 } from 'common/utils';
import { Grades } from 'elements';
import Modal from 'elements/Modal';
import { addProductReview } from 'store/reducers/productSlice/thunks';
import { Button, Label } from 'ui-kit';

import style from './LeaveReviewForm.module.scss';

interface ILeaveReviewForm {
  showModal: boolean;
  handleCloseModal: () => void;
  productId: string | undefined;
}

interface IFormData {
  review: string;
}

export const LeaveReviewForm: FC<ILeaveReviewForm> = ({
  showModal,
  handleCloseModal,
  productId,
}): JSX.Element => {
  const { register, handleSubmit, resetField } = useForm<IFormData>();
  const [stars, setStars] = useState<number>(0);
  const [selectedStars, setSelectedStars] = useState<number>(0);
  const [imagesToUpload, setImagesToUpload] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const onMouseOverHandler = (starsCount: number): void => {
    setStars(starsCount);
  };

  const onClickHandler = (selectedStarsCount: number): void => {
    setSelectedStars(selectedStarsCount);
  };

  const closeModalHandler = (): void => {
    handleCloseModal();
  };

  const uploadImageHandler = async (imageId: number, image: File): Promise<void> => {
    const imageBase64 = await convertImageToBase64(image);

    setImagesToUpload([...imagesToUpload, imageBase64]);
  };

  const deleteImageHandler = async (imageId: number): Promise<void> => {
    const newArrayOfImages = [...imagesToUpload];

    newArrayOfImages.splice(imageId - 1, 1);
    setImagesToUpload(newArrayOfImages);
  };

  const onSubmit = async (data: IFormData): Promise<void> => {
    const params = {
      product_id: Number(productId),
      product_review_photo: imagesToUpload,
      product_review_text: data.review,
      product_review_grade: selectedStars,
    };

    dispatch(addProductReview(params));
    handleCloseModal();
    setSelectedStars(0);
    setImagesToUpload([]);
    resetField('review');
  };

  return (
    <Modal
      classNameModal={style.review_modal}
      showModal={showModal}
      closeModal={closeModalHandler}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className={style.leave_title}>Leave a review</p>
        <div className={style.rate_container}>
          <div className={style.rate_order}>
            <span>Rate the order</span>
            <Grades
              className={style.grades}
              onlyStars
              fiveStar
              grade={stars || selectedStars}
              count={5}
              starSize="40"
              setStars={onMouseOverHandler}
              setSelectedStars={onClickHandler}
            />
          </div>
        </div>
        <div className={style.review_photos_container}>
          {imagesToUpload &&
            imagesToUpload.map((el, index) => (
              <LeaveReviewImage
                key={index}
                id={index + 1}
                image={el}
                uploadImage={uploadImageHandler}
                deleteImage={deleteImageHandler}
              />
            ))}
          <LeaveReviewImage
            id={imagesToUpload.length + 1}
            image=""
            uploadImage={uploadImageHandler}
            deleteImage={deleteImageHandler}
          />
        </div>
        <div className={style.review_text_container}>
          <Label label="Your review (optional)" htmlFor="review">
            <textarea
              className={style.review_text}
              {...register('review')}
              placeholder="Describe your impressions about the order"
              cols={10}
              rows={5}
            />
          </Label>
        </div>
        <Button
          disabled={!selectedStars}
          className={style.review_button}
          label="Send"
          type="submit"
        />
      </form>
    </Modal>
  );
};
