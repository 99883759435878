import { FC } from 'react';

import cn from 'classnames';

import { WhatsappIcon } from 'assets/icons';
import { Button } from 'ui-kit/buttons/Button/Button';

import style from './WhatsappLinkAsButton.module.scss';

interface IWhatsappLink {
  className?: string;
  phoneNumber?: string | null;
  text?: string;
}

export const WhatsappLinkAsButton: FC<IWhatsappLink> = ({
  phoneNumber,
  className,
  text,
}) => {
  return (
    <Button
      as="a"
      href={`https://wa.me/${phoneNumber}`}
      target="_blank"
      rel="noreferrer"
      className={cn(style.button_link, { [style.disabled]: !phoneNumber }, className)}
    >
      <WhatsappIcon className={style.icon} style={{ color: 'inherit' }} /> {text}
    </Button>
  );
};
