import React from 'react';

import { Title } from 'ui-kit';

import style from './FeedbackAndQuestions.module.scss';

export const FeedbackAndQuestionsPage = (): JSX.Element => {
  return (
    <div className={style.feedback_container}>
      <Title>Coming soon...</Title>
    </div>
  );
};
