export const {
  HOME,
  REGISTER,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  CHANGE_EMAIL,
  VERIFY_CURRENT_EMAIL,
  ADD_NEW_EMAIL,
  VERIFY_NEW_EMAIL,
  CONFIRM_EMAIL,
  CHECK_EMAIL,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  PRODUCTS_LIST,
  NEWS,
  CONTACT,
  SELL,
  TUTORIALS,
  ABOUT,
  FAQ,
  DELETE_ACCOUNT,
} = {
  HOME: '/',
  REGISTER: '/register',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot_password',
  RESET_PASSWORD: '/reset_password',
  CHANGE_PASSWORD: '/change_password',
  CHANGE_EMAIL: '/change_email',
  VERIFY_CURRENT_EMAIL: '/change_email/verify_current_email',
  ADD_NEW_EMAIL: '/add_new_email',
  VERIFY_NEW_EMAIL: '/add_new_email/verify_new_email',
  CONFIRM_EMAIL: '/register/confirm_email',
  CHECK_EMAIL: '/register/check_email',
  TERMS_AND_CONDITIONS: '/terms_and_conditions',
  PRIVACY_POLICY: '/privacy_policy',
  PRODUCTS_LIST: '/products_list',
  NEWS: '/news',
  CONTACT: '/contact',
  SELL: '/sell',
  TUTORIALS: '/tutorials',
  ABOUT: '/about',
  FAQ: '/faq',
  DELETE_ACCOUNT: '/delete_account',
} as const;
