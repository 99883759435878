import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { LeaveReviewForm } from './LeaveReviewForm/LeaveReviewForm';
import { ProductReview } from './ProductReview/ProductReview';
import { ProductReviewFilters } from './ProductReviewFilters/ProductReviewFilters';
import { ProductReviewTitle } from './ProductTitle/ProductReviewTitle';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { LoadingStatusEnum } from 'common/types';
import {
  productImagesSelector,
  productNameSelector,
  productReviewsSelector,
  supplierSelector,
} from 'store/reducers/productSlice';
import {
  productLoadingStatusSelector,
  productReviewsGradesSelector,
} from 'store/reducers/productSlice/selectors';
import {
  getImagesByProductId,
  getProductById,
  getProductsReviewsById,
  getReviewGradesByProductId,
} from 'store/reducers/productSlice/thunks';
import { Button, LoaderLinear } from 'ui-kit';
import { Pagination } from 'ui-kit/Pagination/Pagination';

import style from './ProductAllReviews.module.scss';

export const ProductAllReviews = (): JSX.Element => {
  const { productId } = useParams<string>();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [withPhotos, setWithPhotos] = useState<boolean | null>(null);
  const [reviewsGrade, setReviewsGrade] = useState<number | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const productReviewGrades = useAppSelector(productReviewsGradesSelector);
  const reviewsPerPage = 5;
  const initialTotalPages = Math.ceil(productReviewGrades.review_count / reviewsPerPage);
  const [totalPages, setTotalPages] = useState<number>(0);
  const productImages = useAppSelector(productImagesSelector);
  const productReviews = useAppSelector(productReviewsSelector);
  const supplier = useAppSelector(supplierSelector);
  const productImageUrl = productImages[0]?.image_url;
  const productTitle = useAppSelector(productNameSelector);
  const companyName = supplier?.company.name;
  const gradeAverage = supplier?.grade_average;
  const productLoadingStatus = useAppSelector(productLoadingStatusSelector);
  const headerHeight: number | undefined = document.querySelector('header')?.clientHeight;

  useEffect(() => {
    dispatch(getImagesByProductId({ product_id: Number(productId) }));
    dispatch(getReviewGradesByProductId({ product_id: Number(productId) }));
    dispatch(getProductById({ product_id: Number(productId) }));
  }, []);

  useEffect(() => {
    window.scrollTo({ top: headerHeight, behavior: 'auto' });
  }, [currentPage]);

  useEffect(() => {
    const params = {
      product_id: Number(productId),
      grade: reviewsGrade,
      offset: (currentPage - 1) * reviewsPerPage,
      limit: reviewsPerPage,
      with_photos: withPhotos,
    };

    dispatch(getProductsReviewsById(params));
  }, [withPhotos, currentPage, reviewsGrade, productId]);

  useEffect(() => {
    dispatch(getReviewGradesByProductId({ product_id: Number(productId) }));
  }, [withPhotos, reviewsGrade]);

  const onWithPhotosFilter = (): void => {
    if (!withPhotos) {
      setWithPhotos(true);
      setCurrentPage(1);
    } else {
      setWithPhotos(null);
      setCurrentPage(1);
    }
  };

  const onStarsFilterClick = (grade: number, reviewCount: number): void => {
    setReviewsGrade(grade);
    setCurrentPage(1);
    setTotalPages(Math.ceil(reviewCount / reviewsPerPage));
  };

  const handleCloseModal = (): void => {
    setIsOpenModal(false);
  };

  const handleOpenModal = (): void => {
    setIsOpenModal(true);
  };

  return (
    <div className={style.wrapper}>
      {productLoadingStatus === LoadingStatusEnum.Loading ? (
        <LoaderLinear />
      ) : (
        <>
          <div className={style.product_title_button_block}>
            <ProductReviewTitle
              productImageUrl={productImageUrl}
              productTitle={productTitle}
              companyName={companyName}
              gradeAverage={gradeAverage}
              productId={productId}
            />
            <Button
              className={style.feedback_button}
              label="Leave a review"
              type="button"
              onClick={handleOpenModal}
            />
          </div>
          <ProductReviewFilters
            onWithPhotosFilter={onWithPhotosFilter}
            onStarsFilterClick={onStarsFilterClick}
            reviewsCurrentGrades={reviewsGrade}
            productReviewGrades={productReviewGrades}
            withPhotos={withPhotos}
          />
          {productReviews ? (
            productReviews.map(el => (
              <ProductReview
                key={el.id}
                created_at={el.created_at}
                grade={el.grade_overall}
                text={el.text}
                user_name={el.seller.user.first_name}
                photos={el.photos}
              />
            ))
          ) : (
            <div>There are no reviews yet</div>
          )}
          <div className={style.pagination}>
            <Pagination
              totalPages={totalPages || initialTotalPages}
              currentPage={currentPage}
              onPageChanged={setCurrentPage}
            />
          </div>
          <LeaveReviewForm
            productId={productId}
            showModal={isOpenModal}
            handleCloseModal={handleCloseModal}
          />
        </>
      )}
    </div>
  );
};
