import React from 'react';

import { Title } from 'ui-kit';

import style from './DashboardPage.module.scss';

export const DashboardPage = (): JSX.Element => {
  return (
    <div className={style.dashboard_container}>
      <Title>Coming soon...</Title>
    </div>
  );
};
